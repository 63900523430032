@import "src/styles/mixins";

.screen-mail-sent {
  text-align: center;
}

.screen-mail-sent__image {
  justify-self: center;
  margin-bottom: 1rem;

  font-size: 8rem;
  line-height: 1;

  color: var(--text-body-light)
}
