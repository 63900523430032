@import "../../../styles/mixins";
@import "../../../styles/variables.module";

.chat-item_avatar {
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  height: 3.3rem;
}

.chat-item_content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-item_actions {
  flex-grow: 1;
  align-content: flex-end;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr)) !important;

  // fallback for safari
  height: 1%;
}

.chat-item_meta {
  flex-grow: 1;
  min-height: 0; // Fix Safari message text height overflow.
  height: 100%; // Fix Safari message text height overflow.

  .ae-font-icons {
    height: 1rem;

    color: var(--text-title-light);
  }
}
