@import "src/styles/variables.module";
@import "src/styles/mixins";

.row {
  &.br-t {
    border-top-left-radius: $border-radius_md;
    border-top-right-radius: $border-radius_md;
  }

  &.br-b {
    border-bottom-left-radius: $border-radius_md;
    border-bottom-right-radius: $border-radius_md;
  }

  &.br-all {
    border-radius: $border-radius_md;
  }
}
