@use 'sass:math';
@import "variables.module";

@mixin hideScrollbars {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin mobile-only {
  @include until-tablet {
    @content;
  }
}

@mixin until-mobile-lg {
  @media (max-width: #{$mobile-lg-width - 1}) {
    @content;
  }
}

@mixin from-mobile-lg {
  @media (min-width: #{$mobile-lg-width}) {
    @content;
  }
}

@mixin only-mobile-lg {
  @media (min-width: #{$mobile-lg-width}) and (max-width: #{$tablet-width - 1}) {
    @content;
  }
}

@mixin until-tablet {
  @media (max-width: #{$tablet-width - 1}) {
    @content;
  }
}

@mixin from-tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin until-desktop {
  @media (max-width: #{$desktop-width - 1}) {
    @content;
  }
}

@mixin from-desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin until-desktop-lg {
  @media (max-width: #{$desktop-lg-width}) {
    @content;
  }
}

@mixin from-desktop-lg {
  @media (min-width: #{$desktop-lg-width}) {
    @content;
  }
}

@mixin canHover {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin threeDots() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin gradient($direction, $list) {
  background: linear-gradient($direction, $list);
}

@mixin grid-elements($from, $to) {
  @for $i from $from through $to {
    &:nth-child(#{$i}) {
      @content;
    }
  }
}

@mixin lineClamp($line-count, $fallback-line-height: 1rem) {
  @supports (display: -webkit-box) and (-webkit-box-orient: vertical) and (-webkit-line-clamp: 3) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line-count;
    overflow: hidden;
  }

  @supports not ((display: -webkit-box) and (-webkit-box-orient: vertical) and (-webkit-line-clamp: 3)) {
    display: block;
    overflow: hidden;
    line-height: $fallback-line-height;
    max-height: calc(#{$line-count} * #{$fallback-line-height});
  }
}

@mixin flexGap($gap) {
  margin: -#{math.div($gap, 2)};

  > * {
    margin: math.div($gap, 2);
  }
}

@mixin flexGapHorizontal($gap) {
  & > * {
    margin-left: $gap
  }

  & > :first-child {
    margin-left: 0;
  }
}
