@import '../styles/variables.module';
@import "src/styles/mixins";

// Prevents unreadable light colored text on the light background.
@mixin blurBanner() {
  .layout-login {
    .banner-welcome {
      .image-planet {
        opacity: 0.3;
      }
    }
  }
}

.layout-login {
  display: flex;
  min-height: 100%;
}

.layout-login__content {
  flex-grow: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0.9rem;

  color: var(--gray);
}

@media (max-height: 650px) {
  @include blurBanner();
}

@include until-tablet {
  .layout-login {
    flex-direction: column;
  }

  .layout-login__content {
    margin-left: auto;
    margin-right: auto;
  }

  @include blurBanner();
}

@include from-tablet {
  .layout-login {
    .banner-welcome {
      flex-shrink: 0;
      width: 42%;
    }
  }
}

@include from-desktop-lg {
  .layout-login__content {
    margin-left: 120px;
    margin-right: 120px;
  }
}
