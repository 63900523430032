@import "src/styles/variables.module";

.select-arrow {
  display: flex;
  margin-left: $gap_tiny;
  margin-right: $gap_tiny;

  transition: transform 0.25s;
  pointer-events: none;

  &.is-rotated {
    transform: rotate(180deg);
  }
}
