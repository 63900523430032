.password-rule__wrap {
  margin-bottom: 1rem;   
}

.password-rule__item {
  display: flex;
  align-items: center;   
}

.password-rule__icon {
  margin-right: 0.5rem;   
}
