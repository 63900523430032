@import "src/styles/mixins";
@import "src/styles/variables.module";

.resources-view_content-wrap {
  display: grid;
  grid-template-columns: 2fr 3fr;
  height: 100%;
  min-height: 0; // [AE-843] Fix Ipad Pro 2018 height overflow.
}

.resources-view_header-inner {
  width: 100%;
  min-width: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: $gap_lg;
  padding-bottom: $gap_lg;
  border-bottom: 1px solid var(--border);

  @include flexGapHorizontal($gap_lg);
}
