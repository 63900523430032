@import "src/styles/variables.module";

.row {
  &.b {
    border: $border;
  }

  &.b-t {
    border-top: $border;
  }

  &.b-b {
    border-bottom: $border;
  }

  &.b-r {
    border-right: $border;
  }

  &.b-l {
    border-left: $border;
  }
}
