@import "src/styles/variables.module";

$box-shadow: 0 2px 6px rgba(39, 48, 57, 0.1);

.modal_chat-attachments {
  width: 100% !important;
  height: 100% !important;
}

.page-campaigns {
  flex-grow: 1;
}

.page-knowledge {
  display: grid;
  grid-template-columns: 2fr 3fr;
  height: 100%;
}

.camp-row-wrap {
  border-bottom: 1px solid var(--border);
  padding: 0.5rem;
  display: flex;
  flex-direction: row;

  &:hover{
    cursor: pointer;
    background-color: var(--row-hover);
  }
}

.camp-id {
  flex: 1;
}
.camp-name {
  flex: 3;
}
.camp-status {
  flex: 3;
}

.knowledge-list {
  width: 100%;
  max-width: 27.8rem;
  border-right: 1px solid var(--border);
  padding-top: 1.1rem;
}

.tree-node {
  position: relative;
  box-sizing: border-box;
  padding-left: 0 !important;

  .minus-icon, .plus-icon {
    cursor: pointer;
  }

  &.blue {
    .tree__node-inner {
      border-color: var(--blue-light);
      &:hover {
        background-color: var(--blue-opacity);
        .tree-node__arrow {
          color: var(--blue);
        }
        .tree-node__title {
          color: var(--blue);
          text-decoration: underline;
        }
      }
    }
    .badge, .minus-icon, .plus-icon {
      background-color: var(--blue);
    }
    &.is-selected {
      .tree__node-inner {
        background-color: var(--blue-opacity);
        .tree-node__arrow {
          color: var(--blue);
        }
        .tree-node__title {
          color: var(--blue);
          text-decoration: underline;
        }
      }
    }
  }

  &.green {
    .tree__node-inner {
      border-color: var(--green-light);
      &:hover {
        background-color: var(--green-opacity);
        .tree-node__arrow {
          color: var(--green);
        }
        .tree-node__title {
          color: var(--green);
          text-decoration: underline;
        }
      }
    }
    .badge, .minus-icon, .plus-icon {
      background-color: var(--green);
    }
    &.is-selected {
      .tree__node-inner {
        background-color: var(--green-opacity);
        .tree-node__arrow {
          color: var(--green);
        }
        .tree-node__title {
          color: var(--green);
          text-decoration: underline;
        }
      }
    }
  }

  &.orange {
    .tree__node-inner {
      border-color: var(--orange-light);
      &:hover {
        background-color: var(--orange-opacity);
        .tree-node__arrow {
          color: var(--orange);
        }
        .tree-node__title {
          color: var(--orange);
          text-decoration: underline;
        }
      }
    }
    .badge, .minus-icon, .plus-icon {
      background-color: var(--orange);
    }
    &.is-selected {
      .tree__node-inner {
        background-color: var(--orange-opacity);
        .tree-node__arrow {
          color: var(--orange);
        }
        .tree-node__title {
          color: var(--orange);
          text-decoration: underline;
        }
      }
    }
  }

  &.pink {
    .tree__node-inner {
      border-color: var(--red);
      &:hover {
        background-color: var(--red-opacity);
        .tree-node__arrow {
          color: var(--pink);
        }
        .tree-node__title {
          color: var(--pink);
          text-decoration: underline;
        }
      }
    }
    .badge, .minus-icon, .plus-icon {
      background-color: var(--pink);
    }
    &.is-selected {
      .tree__node-inner {
        background-color: var(--red-opacity);
        .tree-node__arrow {
          color: var(--pink);
        }
        .tree-node__title {
          color: var(--pink);
          text-decoration: underline;
        }
      }
    }
  }

  &.is-selected {
    font-weight: 700;
  }
}

.minus-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.1rem;
  height: 1.1rem;

  i, svg {
    display: flex;
    font-size: 0.1rem;
    color: var(--white);
    fill: var(--white);
  }
}

.tree__node-inner {
  width: 100%;
  border-left-width: 0.35rem;
  border-left-style: solid;
  cursor: pointer;
  box-shadow: $box-shadow;

  &.card {
    border-radius: 0.3rem;
  }
}

.tree-node__arrow {
  font-size: 0.6rem;
  display: flex;
  color: var(--gray-dark);
}

.node__icon {
  i {
    display: flex;
    font-size: 1rem;
    color: var(--white);
  }
}

.minus {
  width: 15px;
  height: 2px;
}

.plus-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.1rem;
  height: 1.1rem;

  i {
    display: flex;
    font-size: 0.45rem;
    transform: rotate(45deg);
    color: var(--white);
  }
}
