@import "src/styles/variables.module";

.btn-close {
  i {
    position: relative;
    top: 1px;

    margin-right: $gap_sm;

    font-size: 0.65rem;

    color: var(--text-title-light);
  }
}
