@import "src/styles/mixins.scss";

.banner-welcome {
  position: relative;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  background-color: var(--blue);
  color: var(--white);

  pointer-events: none;
  user-select: none;

  background-repeat: no-repeat;
  background-size: cover;

  .greeting {
    z-index: 1;
  }

  .logo {
    box-sizing: border-box;
    height: 4rem;
    margin-bottom: 2.5rem;
  }

  h1 {
    margin-bottom: 1.2rem;
  }

  .image-planet {
    z-index: 0;
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    line-height: 0;

    img {
      z-index: 1;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

@include until-tablet {
  .banner-welcome {
    .greeting {
      margin: 2.4rem 0.9rem;
    }
  }
}

@include tablet-only {
  .banner-welcome {
    .greeting {
      margin: 120px 2rem 2.4rem;
    }
  }
}

@include from-desktop {
  .banner-welcome {
    .greeting {
      margin: 120px 68px 68px;
    }
  }
}
