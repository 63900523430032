@import "src/styles/variables.module";

.field-label {
  margin-left: 0.2rem;
  margin-right: 0.2rem;

  white-space: nowrap;

  &.sm {
    font-size: 0.8rem;
    margin-bottom: $gap_tiny;
  }

  &.lg {
    font-size: 1rem;
  }
}
