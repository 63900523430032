@import '../styles/variables.module';
@import '../styles/mixins';

$menu-z-index: 0; // Is below the content when not sliding panel to close the language popup when click on side menu.
$content-z-index: 1;
$menu-sliding-z-index: $content-z-index + 1;

.layout-main {
  height: 100%;
  display: flex;
  position: relative;

  background: var(--bg-light);
}

.layout-main__menu {
  z-index: $menu-z-index;

  &.has-sliding-menu {
    z-index: $menu-sliding-z-index;
  }
}

.layout-main__content {
  z-index: $content-z-index;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.layout-main__content__main {
  flex-grow: 1;
  box-sizing: border-box;

  min-height: 0; // Fix height overflow when many words in the chat input.
  height: 100%; // Fix Safari height 0.
  display: flex;
  flex-direction: column;
  padding: 0.9rem;

  &.content-centered {
    justify-content: center;
    align-items: center;
  }
}

.layout-main__content__main > .paper {
  height: 100%; // Does every page's height fully.
}

.flex-height {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}
