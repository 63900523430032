@import "src/styles/variables.module";
@import "src/styles/mixins";

.multi-select-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;

  // Fix list height overflow.
  .scrollbars-wrapper {
    display: flex;
    flex-direction: column;
  }
  // ----------

  > * {
    margin-top: $gap_md;
  }

  >:first-child {
    margin-top: 0;
  }
}

.multi-select-list_-scrollbars {
  display: flex;
  flex-direction: column;
}

.multi-select-list_input-wrapper {
  flex-shrink: 0;
}

.multi-select-list_checkbox {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: start;
}
