.tabs-head {
  $children-gap: 0.85rem;

  min-width: 0; // Prevent grow parent's width.
  display: flex;
  margin-left: -#{$children-gap};
  margin-right: -#{$children-gap};

  > * {
    margin-left: $children-gap;
    margin-right: $children-gap;
  }
  .tooltip-wrap{
    overflow: hidden;
    white-space: nowrap;
    display: flex;
  }
}
