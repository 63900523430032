@import "src/styles/variables.module";

// Main wrapper.
.react-calendar {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
}

// Buttons.
.react-calendar__navigation__arrow,
.react-calendar__navigation__label,
.react-calendar__tile {
  padding: 0.3rem 0.7rem;
  border: none;
  border-radius: $border-radius_md;

  background-color: transparent;
  outline: none;

  &:not([disabled]) {
    cursor: pointer;

    &:hover {
      background-color: var(--item-active);
    }
  }
}

// Left, right arrows and current month, year, decade wrapper.
.react-calendar__navigation {
  display: grid !important;
  grid-template-columns: 1fr 2fr 1fr;
  padding-top: $gap_sm;
  padding-bottom: $gap_extra-lg;
}

// Current month, year, decade element.
.react-calendar__navigation__label__labelText {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  font-weight: 700;

  color: var(--text-title);
}

// Weekdays names and days (month view) wrapper.
.react-calendar__month-view {
  width: 100%;
  display: flex;

  & > div {
    width: 100%;
    align-items: unset !important;

    & > div {
      display: flex;
      flex-direction: column;
    }
  }
}

// Weekdays wrapper.
.react-calendar__month-view__weekdays {
  padding-bottom: $gap_extra-sm;
}

// Weekday name element.
.react-calendar__month-view__weekdays__weekday {
  font-family: 'Roboto', sans-serif;

  font-size: 0.9rem;
  text-align: center;
  font-weight: 700;

  color: var(--blue);

  abbr {
    text-decoration: none;
  }
}

// Month days wrapper.
.react-calendar__month-view__days {
  height: 100%;
  display: grid !important;
  align-content: space-between;
  justify-content: space-around;
  grid-template-columns: repeat(7, 2rem);
  grid-template-rows: repeat(5, 2rem);
  grid-gap: $gap_tiny;
}

// Day button.
.react-calendar__month-view__days__day {
  padding: 0.1rem;
}

// Day, month, year buttons.
.react-calendar__tile {
  max-width: unset !important;

  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.5;

  color: var(--text-body);

  &[disabled] {
    color: var(--text-body-light);
  }
}

// Day, month, year active.
.react-calendar__tile--active {
  background-color: var(--blue) !important;
  color: var(--white) !important;

  cursor: default !important;
}

// Year wrappers.
.react-calendar__viewContainer,
.react-calendar__year-view,
.react-calendar__decade-view {
  flex-grow: 1;

  display: flex;
}

// Month wrappers.
.react-calendar__decade-view__years,
.react-calendar__year-view__months {
  width: 100%;
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  justify-content: space-between;
  align-content: space-between;
}

// Month button.
.react-calendar__year-view__months__month {
  padding-top: $gap_extra-sm;
  padding-bottom: $gap_extra-sm;
}
