@import "src/styles/mixins";

.select-input {
  &:not(.disabled) {
    cursor: pointer;
  }
}

.select-input_input {
  text-overflow: ellipsis;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
}

.select-input_arrow {
  color: var(--text-title-light);
}
