.input-tags {
  flex-wrap: wrap;

  &.has-tags {
    .input-tags__input {
      padding-right: 1.3rem;
    }
  }

  &:not(.has-tags) {
    .input-tags__btn-reset {
      display: none;
    }
  }

  .input-tags__btn-reset {
    position: absolute;
    top: 0;
    right: 0.45rem;

    padding-left: 0.2rem;
    padding-right: 0;

    line-height: 0;

    color: var(--gray);

    i {
      font-size: 0.7rem;
    }
  }
}

.input-tags__tag {
  margin: 0.15rem 0.45rem 0.15rem -0.3rem;
}
