@import "src/styles/variables.module";

.divider {
  position: relative;

  display: flex;
  align-items: center;

  text-align: center;

  background-color: var(--scheme_color);

  &:before,
  &:after {
    flex-grow: 1;
    height: 1px;

    background-color: var(--border);

    content: "";
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.divider__text {
  position: relative;
  z-index: 1;
  padding-left: $gap_md;
  padding-right: $gap_md;

  color: var(--gray);
}
