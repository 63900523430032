@import "src/styles/mixins";

.textarea {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-top: $gap_lg;
  padding-bottom: $gap_lg;
  border: none;

  font-family: Roboto, 'sans-serif';
  font-size: 0.9rem;

  background: transparent;
  color: var(--text-body);
  outline: none;

  resize: none;

  &::placeholder {
    color: var(--text-title-light);
  }

  @include hideScrollbars();
}
