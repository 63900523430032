@import "../../styles/mixins";

.root {
  position: relative;

  /* Center the loader vertically, horizontally. */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* ============ */

  min-height: 0; // Fix height overflow when many text in the field.
  height: 100%; // Fix Ipad no height.
}

.actions {
  z-index: 1;
  position: absolute;
  top: 0.2rem;
  right: 0.5rem;

  display: flex;
  @include flexGapHorizontal($gap_sm);
}

.btn-expand {
  i {
    font-size: 1rem;
  }
}