@import "src/styles/mixins";
@import "styles/color-schemes";
@import "styles/typography-variants";
@import "styles/split-pane";

html, body, ul {
  padding: 0;
  margin: 0;
}

blockquote {
  margin-block: 0;
  margin-inline: 0;
}

:root {
  --blue-opacity: rgba(36, 122, 243, 0.2);
  --blue: #3185FC;
  --blue-light: #86B8FD;
  --blue-dark: #07477B;
  --blue-gradient-from: #247AF3;
  --blue-gradient-to: var(--blue-dark);
  --blue-gradient-hover-from: #3E79C4;
  --blue-gradient-hover-to: #1B54AD;

  --role-color-opacity: var(--blue-opacity);
  --role-color: var(--blue);
  --role-color-light: var(--blue-light);
  --role-color-dark: var(--blue-dark);

  --role-color-gradient-from: var(--blue-gradient-from);
  --role-color-gradient-to: var(--blue-dark);
  --role-color-gradient-hover-from: var(--blue-gradient-hover-from);
  --role-color-gradient-hover-to: var(--blue-gradient-hover-to);

  --green-opacity: rgba(97, 163, 49, 0.2);
  --green: #61A331;
  --green-light: #7CAE58;
  --green-dark: #32720A;
  --green-aqua: #4CC9C2;
  --green-gradient-from: var(--green-light);
  --green-gradient-to: #4A9419;
  --green-gradient-hover-from: var(--green);
  --green-gradient-hover-to: var(--green-dark);

  --gray-opacity: #ECF1F8; // 'grayBtn' in {constants.js}
  --gray: #45575F;
  --gray-light: #C7C7CC; // 'grayMedium' in {constants.js}
  --gray-dark: #3C4148; // 'textBody' in {constants.js}

  --red-opacity: rgba(218, 44, 56, 0.2);
  --red: #DA2C38; // 'redMedium' in {constants.js}
  --red-light: #F97E73;
  --red-dark: #D2111C;
  --red-gradient-from: var(--red-light);
  --red-gradient-to: var(--text-error);
  --red-gradient-hover-from: #F75B4F;
  --red-gradient-hover-to: var(--red-dark);

  --orange-opacity: rgba(255, 170, 51, 0.2);
  --orange: #FF9500;
  --orange-light: #FFAA33;
  --orange-dark: #F58F00;

  --violet-opacity: #E6DFF6;
  --violet: #634FA2;
  --violet-bright: #CC32C5;

  --pink: #FF375F;
  --olive: #C0DDAA;
  --lime: #DDECD2;
  --table-row:  #F8FCF6;
  --chat-reminder: #356CBA;
  --highlighted: #FFEED6;

  --black: #000000;

  --white: #ffffff;
  --white-70: rgba(255, 255, 255, 0.7);
  --white-60: rgba(255, 255, 255, 0.6);
  --white-40: rgba(255, 255, 255, 0.4);

  --text-title: #45575F;
  --text-title-light: #7A8799;
  --text-body: #3C4148;
  --text-body-light: #C7C7CC;

  --text-error: #DA2C38;

  --table-hover: #E4F0FF;
  --almost-white: #FAFBFD;
  --bg-light: #F2F6FC;
  --row-hover: #3185fc44;
  --item-active: #E0EDFF;
  --border: rgba(60, 60, 67, 0.2);
}

html {
  font-size: 18px;
}

html, body, #root {
  height: 100%;
}

#root {
  position: relative;
  z-index: 0;
}

#root-after {
  position: relative;
  z-index: 1;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}

a {
  color: var(--blue);

  &:not([target="_blank"]) {
    text-decoration: none;
  }
}
