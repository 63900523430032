@import "src/styles/mixins";

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.35rem;
  padding-right: 0.35rem;
  border-radius: $border-radius_md;

  background-color: var(--scheme_color);
  color: var(--white);

  font-size: 0.8rem;
  line-height: 1.5;
  font-weight: bold;

  &.outline {
    color: var(--scheme_color);
    border: 1px solid var(--scheme_color);
    background-color: transparent;
  }
}
