@import 'src/styles/variables.module';

.list-campaign-item {
  display: grid;
  align-items: center;
  gap: $gap-md;
  grid-template-columns: 1fr auto;

  &.hover {
    .list-campaign-item_title {
      cursor: pointer;

      &:hover {
        color: var(--blue) !important;
        text-decoration: underline;
      }
    }
  }
}
