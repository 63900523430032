@import 'src/styles/mixins';

$transition-duration: 0.1s;

.field {
  position: relative;

  display: flex;
  flex-direction: column;

  &.has-error-gap {
    box-sizing: border-box;
    padding-bottom: 1rem;
  }
}

.field__text-error {
  position: absolute;
  top: 100%;
  left: 0;

  box-sizing: border-box;
  width: 100%;
  padding-left: 0.9rem;
  margin-top: 0.1rem;
  transform: translateY(-100%);
}
