@import "src/styles/variables.module";

.input-clean {
  flex-grow: 1;

  box-sizing: border-box;
  min-width: 0; // Fix input width overflow (e.g. in {InputPhoneNumber}).
  padding-left: 0; // Reset for Ipad.
  padding-right: 0; // Reset for Ipad.
  border: none;

  font-family: Roboto, sans-serif;
  line-height: 1.5;

  background: none;
  color: var(--text-body);
  outline: none;
  -moz-appearance: textfield;

  // Remove input background colour for Chrome autocomplete.
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
  }

  // Remove Edge password reveal button, search clear button.
  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  // Remove input 'number' buttons.
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: var(--text-title-light);
  }

  &.disabled {
    color: var(--text-body);

    &::placeholder {
      color: var(--text-body);
    }
  }

  &.sm {
    padding-top: $gap_extra-sm;
    padding-bottom:$gap_extra-sm;

    font-size: 0.8rem;
  }

  &.lg {
    padding-top: $gap_sm;
    padding-bottom: $gap_sm;

    font-size: 1rem;
  }
}

