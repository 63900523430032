.message-list_scroll-down {
  z-index: 1;
  position: sticky;
  position: -webkit-sticky; // for Ipad.
  height: 0; // prevent doing empty space in messages view.
  top: calc(100% - 3rem);
  text-align: right;

  pointer-events: none;

  button {
    margin-left: auto;
    pointer-events: all;
  }

  i {
    transform: rotate(90deg);
  }
}

.message-list_badge-unread {
  position: absolute;
  right: -0.6rem;
  top: 0;
}
