@import "src/styles/mixins";
@import "src/styles/variables.module";

@include until-tablet {
  .modal-actions {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-gap: $gap_md;
  }
}

@include from-tablet {
  .modal-actions {
    display: flex;
    justify-content: center;

    > button {
      margin-left: $gap_lg;
    }

    > button:first-child {
      margin-left: 0;
    }
  }
}
