// Experimental feature to reduce components colors describing (e.g. 'outline-blue', 'outline-green', ....).

/** see usage example in 'Button.scss' */

.role-color {
  --scheme_color-opacity: var(--role-color-opacity);

  --scheme_color: var(--role-color);
  --scheme_color-light: var(--role-color-light);
  --scheme_color-dark: var(--role-color-dark);

  --scheme_color-gradient-from: var(--role-color-gradient-from);
  --scheme_color-gradient-to: var(--role-color-gradient-to);
  --scheme_color-gradient-hover-from: var(--role-color-gradient-hover-from);
  --scheme_color-gradient-hover-to: var(--role-color-gradient-hover-to);
}

.green {
  --scheme_color-opacity: var(--green-opacity);

  --scheme_color: var(--green);
  --scheme_color-light: var(--green-light);
  --scheme_color-dark: var(--green-dark);

  --scheme_color-gradient-from: var(--green-gradient-from);
  --scheme_color-gradient-to: var(--green-gradient-to);
  --scheme_color-gradient-hover-from: var(--green-gradient-hover-from);
  --scheme_color-gradient-hover-to: var(--green-gradient-hover-to);
}

.blue {
  --scheme_color-opacity: var(--blue-opacity);

  --scheme_color: var(--blue);
  --scheme_color-light: var(--blue-light);
  --scheme_color-dark: var(--blue-dark);


  --scheme_color-gradient-from: var(--blue-gradient-from);
  --scheme_color-gradient-to: var(--blue-gradient-to);
  --scheme_color-gradient-hover-from: var(--blue-gradient-hover-from);
  --scheme_color-gradient-hover-to: var(--blue-gradient-hover-to);
}

.red {
  --scheme_color-opacity: var(--red-opacity);

  --scheme_color: var(--red);
  --scheme_color-light: var(--red-light);
  --scheme_color-dark: var(--red-dark);
  
  --scheme_color-gradient-from: var(--red-gradient-from);
  --scheme_color-gradient-to: var(--red-gradient-to);
  --scheme_color-gradient-hover-from: var(--red-gradient-hover-from);
  --scheme_color-gradient-hover-to: var(--red-gradient-hover-to);
}

.red-light {
  --scheme_color: var(--red-light);
}

.red-dark {
  --scheme_color: var(--red-dark);
}

.gray {
  --scheme_color-opacity: var(--gray-opacity);

  --scheme_color: var(--gray);
  --scheme_color-light: var(--gray-light);
  --scheme_color-dark: var(--gray-dark);
}

.gray-light {
  --scheme_color-opacity: var(--gray-opacity);

  --scheme_color: var(--gray-light);
  --scheme_color-light: var(--gray-light);
  --scheme_color-dark: var(--gray);
}

.gray-opacity {
  --scheme_color-opacity: var(--gray-opacity);

  --scheme_color: var(--gray-opacity);
  --scheme_color-light: var(--gray-light);
  --scheme_color-dark: var(--gray);
}

.border {
  --scheme_color: var(--border);
}

.green-aqua {
  --scheme_color: var(--green-aqua);
}

.text-title-light {
  --scheme_color-opacity: var(--gray-opacity);

  --scheme_color: var(--text-title-light);
  --scheme_color-light: var(--gray-light);
  --scheme_color-dark: var(--gray);
}

.blue-light {
  --scheme_color-opacity: var(--blue-opacity);

  --scheme_color: var(--blue-light);
  --scheme_color-light: var(--blue-light);
  --scheme_color-dark: var(--blue-light);
}

.blue-opacity {
  --scheme_color-opacity: var(--blue-opacity);

  --scheme_color: var(--blue-opacity);
  --scheme_color-light: var(--blue-light);
  --scheme_color-dark: var(--blue-light);
}

.blue-dark {
  --scheme_color-opacity: var(--gray-dark-opacity);

  --scheme_color: var(--blue-dark);
  --scheme_color-light: var(--chat-reminder); // Can be changed. It just seems OK for the "detach" attachment btn.
  --scheme_color-dark: var(--blue-dark);
}

.orange {
  --scheme_color-opacity: var(--orange-opacity);

  --scheme_color: var(--orange);
  --scheme_color-light: var(--orange-light);
  --scheme_color-dark: var(--orange-dark);
}

.orange-light {
  --scheme_color-opacity: var(--orange-opacity);

  --scheme_color: var(--orange-light);
  --scheme_color-dark: var(--orange-dark);
}

.violet {
  --scheme_color-opacity: var(--violet-opacity);

  --scheme_color: var(--violet);
  --scheme_color-light: var(--violet);
  --scheme_color-dark: var(--gray-dark); // Can be changed. It just seems OK for the send/record btn.
}

.violet-opacity {
  --scheme_color: var(--violet-opacity);
  --scheme_color-dark: var(--violet);
}

.violet-bright {
  --scheme_color: var(--violet-bright);
}

.white {
  --scheme_color-opacity: var(--white);

  --scheme_color: var(--white);
  --scheme_color-light: var(--gray-light);
  --scheme_color-dark: var(--gray-dark);
}

.lime {
  --scheme_color-opacity: var(--lime);

  --scheme_color: var(--lime);
  --scheme_color-light: var(--lime);
  --scheme_color-dark: var(--lime);
}

.item-active {
  --scheme_color: var(--item-active);
}

.almost-white {
  --scheme_color: var(--almost-white);
}

.table-row {
  --scheme_color: var(--table-row);
}
