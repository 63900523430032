.layout-loading {
  height: 100%;

  animation: fadeIn 1s;
}

.layout-loading__banner {
  height: 100%;
  text-align: center;

  .greeting {
    display: grid;
    height: 100%;
    justify-items: center;
  }

  .image-planet {
    filter: blur(14px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
